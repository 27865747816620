import { getCompanyName, getCompanyRole, urlWithSlug } from 'helper/utils';
import { Avatar, BubbleBox, Button, Text } from '@hol-jsp/dashboard-dsl';
import { useTranslation } from 'react-i18next';
import Divider from './Divider';
import { UserPlus, Bookmarks, SignOut } from 'phosphor-react';
import Link from 'next/link';
import { useUserInfo } from '@/provider/UserProvider';

const AccountBubble = ({
  onClick,
  logout,
}: {
  onClick: () => void;
  logout: () => void;
}) => {
  const { t } = useTranslation();
  const companyName = getCompanyName();
  const { user } = useUserInfo();
  const role = getCompanyRole();

  return (
    <BubbleBox showHandler={onClick}>
      <div className="absolute top-full right-[40.44px] -translate-y-3 mt-5 z-10">
        <div className="bg-white w-[277px] px-6 py-3 text-black rounded border-[1px] border-neutral-200">
          <div className="flex flex-col">
            <Text as="body-1" className="font-inter font-bold mb-2">
              {t('profile')}
            </Text>
            {!!user && (
              <div className="flex flex-col items-start font-inter">
                <Avatar name={user.name} as="div" />
                <div className="space-y-[5px] mt-2">
                  <Text
                    as="body-2"
                    className="text-primary-400 font-semibold mb-[5px] [overflow-wrap:anywhere]"
                  >
                    {user.name}
                  </Text>
                  <Text
                    as="body-2"
                    className="text-neutral-300 italic [overflow-wrap:anywhere]"
                  >
                    {user.email}
                  </Text>
                  <Text
                    as="body-2"
                    className="text-neutral-300 italic [overflow-wrap:anywhere] "
                  >
                    {companyName}
                  </Text>
                  <div className="border rounded px-2 py-1 border-neutral-75 bg-neutral-50 text-xs text-neutral-300 inline-flex items-center gap-2">
                    <UserPlus size={20} />
                    <span>{role?.name}</span>
                  </div>
                </div>
              </div>
            )}
            <div className="-mx-6 mt-2">
              <Link href={urlWithSlug('/bookmark')}>
                <Button
                  variant="text"
                  className="!text-primary-main !w-full !flex !items-center"
                  onClick={onClick}
                >
                  <Bookmarks size={20} className="mr-2" />
                  {t('bookmark.bookmark')}
                </Button>
              </Link>
            </div>
            <Divider className="!my-0" />
            <Button
              variant="text"
              className="!text-primary-main !w-full !flex !-mx-6 !items-center"
              onClick={logout}
            >
              <SignOut size={20} className="mr-2" />
              {t('logout')}
            </Button>
          </div>
        </div>
      </div>
    </BubbleBox>
  );
};

export default AccountBubble;
