import { BubbleBox, Button, Text } from '@hol-jsp/dashboard-dsl';
import { useTranslation } from 'react-i18next';
import 'twin.macro';
import Divider from './Divider';
import { Buildings, UserGear, Monitor } from 'phosphor-react';
import { Can, urlWithSlug, usePermission } from 'helper';
import Link from 'next/link';

const SettingsBubble = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation();
  const { hasPermissions } = usePermission();

  const hasBillingInfo = hasPermissions([
    'view-billing',
    'update-subscription',
  ]);

  const hasViewUser = hasPermissions([
    'view-user',
    'add-user',
    'edit-user',
    'delete-user',
  ]);

  const hasViewGroup = hasPermissions([
    'view-group',
    'add-group',
    'edit-group',
    'delete-group',
    'edit-group-level',
  ]);

  const hasViewRole = hasPermissions([
    'view-role',
    'add-role',
    'edit-role',
    'delete-role',
  ]);

  const hasViewRoleCompliance = hasPermissions([
    'view-role-compliance',
    'add-role-compliance',
    'edit-role-compliance',
    'delete-role-compliance',
  ]);

  const companyManagementEntryPoint = hasBillingInfo
    ? urlWithSlug('/company-management/billing-info')
    : hasViewUser
      ? urlWithSlug('/company-management/user')
      : hasViewGroup
        ? urlWithSlug('/company-management/user-group')
        : hasViewRole
          ? urlWithSlug('/company-management/privilege')
          : hasViewRoleCompliance
            ? urlWithSlug('/company-management/role-compliance')
            : '';

  const hasThemeSettingPermission = hasPermissions(['edit-theme-setting']);

  return (
    <BubbleBox showHandler={onClick}>
      <div className="absolute top-full right-[40.44px] mt-5 mr-12 -translate-y-3 z-10">
        <div className="bg-white w-[277px] p-3 text-black rounded border-[1px] border-neutral-200">
          <div className="flex flex-col">
            <Text as="body-1" className="font-inter font-bold px-3">
              {t('settings.settings')}
            </Text>
            <Divider />
            <div className="-mx-3">
              <Link
                href={urlWithSlug('/personal-setting/account')}
                data-testid="personalSetting"
              >
                <Button
                  variant="text"
                  className="!text-primary-main !w-full !flex !items-center"
                  onClick={onClick}
                >
                  <UserGear
                    size={20}
                    className="mr-2 text-primary-400"
                    weight="duotone"
                  />
                  {t('settings.personal')}
                </Button>
              </Link>
              <Can
                permissions={[
                  'edit-theme-setting',
                  'upload-initial-content',
                  'upload-update-content',
                  'restore-backup',
                ]}
              >
                <Link
                  href={urlWithSlug(
                    `/rcs-setting/${hasThemeSettingPermission ? 'theme' : 'data'}`
                  )}
                  data-testid="RCS-Setting"
                >
                  <Button
                    variant="text"
                    className="!text-primary-main !w-full !flex !items-center"
                    onClick={onClick}
                  >
                    <Monitor
                      size={20}
                      className="mr-2 text-primary-400"
                      weight="duotone"
                    />
                    {t('settings.rcs')}
                  </Button>
                </Link>
              </Can>
              {companyManagementEntryPoint !== '' && (
                <Link
                  href={companyManagementEntryPoint}
                  data-testid="companyManagement"
                >
                  <Button
                    variant="text"
                    className="!text-primary-main !w-full !flex !items-center"
                    onClick={onClick}
                  >
                    <Buildings
                      size={20}
                      className="mr-2 text-primary-400"
                      weight="duotone"
                    />
                    {t('settings.company')}
                  </Button>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </BubbleBox>
  );
};

export default SettingsBubble;
