/* eslint-disable no-unused-vars */
import {
  AutocompleteItemProp,
  Checkbox,
  PaginationData,
  TextDS2,
  Divider,
  Button,
  Dropdown,
  IDropdownItemProps,
  Select,
  SelectOption,
} from '@hol-jsp/dashboard-dsl';
import { useMemo, useState, useEffect, Fragment } from 'react';
import { CenteredLoadingImage } from 'components/layout/Layout';
import { CheckableDataChecklistItem, ISelectAllMode } from './types';
import { t } from 'i18next';
import 'twin.macro';
import { TablePagination } from '../TablePagination';
import classNames from 'classnames';
import { DataChecklistItem } from './DataChecklistItem';
import { EmptyState, ErrorState } from 'components';
import Toggle from 'components/Toggle/Toggle';
import { ArrowsDownUp, CaretDown } from 'phosphor-react';
import { Can } from 'helper';
import { useTrackedAction } from '@/helper/actionTracker';
import { useRouter } from 'next/router';

export function DataChecklist<T extends CheckableDataChecklistItem>({
  searchQuery,
  pagination,
  setPagination,
  isLoading,
  isError,
  isFetching,
  error,
  title,
  selectedItemRenderer,
  items,
  setItems,
  itemRenderer,
  onItemClick,
  titleRenderer,
  selectedCount,
  selectable,
  hoverable,
  hideSelectedCount = false,
  selected,
  showToggle = false,
  toggleLabel,
  onClickToggle,
  showSortButton = false,
  selectedItem,
  setSelectedItem,
  disabledAndChecked = false,
  searchKeyword,
  isOnSearchPage = false,
  isOnBookmarkPage = false,
  sortBtnItems,
  selectedSortType,
  setCheckboxId,
  checkboxId,
  isShowViewComplianceBtn = false,
  isObligationUpdates = false,
  onChangeSelectAllMode,
  changeSelectAllModeTo,
  isSimpleSelectAllMode,
  toggleValue,
  itemDataTestId,
  sectorTitle,
  itemCheckboxTestId,
  checkboxSelectAllTestId,
}: {
  search?: AutocompleteItemProp | null | undefined;
  searchQuery?: string;
  pagination: PaginationData;
  setPagination: React.Dispatch<React.SetStateAction<PaginationData>>;
  isLoading?: boolean;
  isFetching?: boolean;
  isError?: boolean;
  error?: any;
  title?: string;
  items: T[];
  setItems: React.Dispatch<React.SetStateAction<T[]>>;
  itemRenderer: ({
    item,
    searchQuery,
  }: {
    item: T;
    searchQuery?: string;
  }) => React.ReactNode;
  selectedItemRenderer?: ({
    item,
    clear,
  }: {
    item: T;
    clear: () => void;
  }) => React.ReactNode;
  onItemClick?: (item: T, index: number) => void;
  titleRenderer?: React.ReactNode;
  selectedCount?: ({
    selectedItems,
    items,
  }: {
    selectedItems: T[];
    items: T[];
  }) => React.ReactNode;
  selectable?: boolean;
  hoverable?: boolean;
  hideSelectedCount?: boolean;
  selected?: T | undefined;
  showToggle?: boolean;
  toggleLabel?: string;
  onClickToggle?: (value: boolean) => void;
  showSortButton?: boolean;
  selectedItem?: T | undefined;
  setSelectedItem?: React.Dispatch<React.SetStateAction<T | undefined>>;
  disabledAndChecked?: boolean;
  searchKeyword?: string;
  isOnSearchPage?: boolean;
  isOnBookmarkPage?: boolean;
  sortBtnItems?: IDropdownItemProps[];
  selectedSortType?: string;
  setCheckboxId?: React.Dispatch<React.SetStateAction<Array<string>>>;
  checkboxId?: Array<string>;
  isShowViewComplianceBtn?: boolean;
  isObligationUpdates?: boolean;
  onChangeSelectAllMode?: (value: ISelectAllMode) => void;
  changeSelectAllModeTo?: ISelectAllMode;
  isSimpleSelectAllMode?: boolean;
  toggleValue?: boolean;
  itemDataTestId?: string;
  sectorTitle?: string;
  itemCheckboxTestId?: string;
  checkboxSelectAllTestId?: string;
}) {
  const trackAction = useTrackedAction();
  const router = useRouter();

  const selectAllOptions = [
    {
      label: 'Select all to activate',
      value: 'activate',
      onClick: () => setSelectedAllMode('Select all to activate'),
      'data-testid': 'select-all-to-activate',
    },
    {
      label: 'Select all to confirm updates',
      value: 'confirm',
      onClick: () => setSelectedAllMode('Select all to confirm updates'),
      'data-testid': 'select-all-to-confirm-updates',
    },
    {
      label: 'Select all to inactivate',
      value: 'inactivate',
      onClick: () => setSelectedAllMode('Select all to inactivate'),
      'data-testid': 'select-all-to-inactivate',
    },
  ];

  const [selectedAllMode, setSelectedAllMode] = useState<ISelectAllMode>(
    changeSelectAllModeTo ||
      (isObligationUpdates
        ? 'Select all to confirm updates'
        : 'Select all to activate')
  );

  useEffect(() => {
    onChangeSelectAllMode?.(selectedAllMode);
    setItems((prev) =>
      prev.map((item) => ({
        ...item,
        checked: false,
      }))
    );
  }, [selectedAllMode]);

  const handleItemClick = (item: T, _index: number) => {
    setSelectedItem?.(item);
    onItemClick?.(item, _index);
  };

  function toggleSelectAll(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.checked) {
      setCheckboxId?.(items.map((item) => item.id));
    } else {
      setCheckboxId?.([]);
    }
    setItems((prev) =>
      prev.map((item) => {
        if (isSimpleSelectAllMode) {
          return {
            ...item,
            checked: e.target.checked,
          };
        }
        return {
          ...item,
          checked:
            (selectedAllMode === 'Select all to activate' &&
              !item.is_activated) ||
            (selectedAllMode === 'Select all to confirm updates' &&
              item.confirmation_status === 'need-confirmation') ||
            (selectedAllMode === 'Select all to inactivate' &&
              item.is_activated &&
              item.confirmation_status !== 'need-confirmation')
              ? e.target.checked
              : false,
        };
      })
    );
    trackAction({
      action: 'select-all-checklist',
      type: isObligationUpdates ? 'obligation-updates' : 'sector-details',
      category: isObligationUpdates
        ? 'Obligation Updates Page'
        : 'Sector Details Page',
      status: 'success',
      data: {
        sector_title: sectorTitle,
        sector_id: router.query.id,
        checked: e.target.checked,
      },
    });
  }

  const selectedItems = useMemo(() => {
    return items.filter((item) => item.checked);
  }, [items]);

  const isAllItemsChecked = useMemo(() => {
    if (items.length === 0) return false;

    if (isSimpleSelectAllMode) {
      return items.every((item) => item.checked);
    }

    const inactiveItems = items.filter((item) => !item.is_activated);
    const needConfirmationItems = items.filter(
      (item) => item.confirmation_status === 'need-confirmation'
    );
    const activeWithNoNeedConfirmationItems = items.filter(
      (item) =>
        item.is_activated && item.confirmation_status !== 'need-confirmation'
    );

    if (
      selectedAllMode === 'Select all to activate' &&
      inactiveItems.length > 0 &&
      inactiveItems.every((item) => item.checked)
    )
      return true;
    else if (
      selectedAllMode === 'Select all to confirm updates' &&
      needConfirmationItems.length > 0 &&
      needConfirmationItems.every((item) => item.checked)
    )
      return true;
    else if (
      selectedAllMode === 'Select all to inactivate' &&
      activeWithNoNeedConfirmationItems.length > 0 &&
      activeWithNoNeedConfirmationItems.every((item) => item.checked)
    )
      return true;
  }, [items, selectedAllMode]);

  const isSomeItemsChecked = useMemo(() => {
    return items.some((item) => item.checked) && !isAllItemsChecked;
  }, [isAllItemsChecked, items]);

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    item: T
  ) => {
    if (!checkboxId?.includes(item.id)) {
      setCheckboxId?.((prevState) => [...prevState, item.id]);
    } else {
      setCheckboxId?.((prevState) => prevState.filter((i) => i !== item.id));
    }
    setItems((prev) =>
      prev.map((prevItem) => {
        if (prevItem.id === item.id) {
          prevItem.checked = e.target.checked;
        }
        return prevItem;
      })
    );
    trackAction({
      action: 'checklist-item',
      type: isObligationUpdates ? 'obligation-updates' : 'sector-details',
      category: isObligationUpdates
        ? 'Obligation Updates Page'
        : 'Sector Details Page',
      status: 'success',
      data: {
        sector_title: sectorTitle,
        sector_id: router.query.id,
        checked: e.target.checked,
        checked_item: { ...item },
      },
    });
  };

  useEffect(() => {
    if (selected) {
      setSelectedItem?.(selected);
    }
  }, [selected]);

  useEffect(() => {
    if (changeSelectAllModeTo) {
      setSelectedAllMode(changeSelectAllModeTo);
    }
  }, [changeSelectAllModeTo]);

  if (isLoading || isFetching) {
    return <CenteredLoadingImage className="relative w-full" />;
  }

  if (isError) {
    return <ErrorState error={error} />;
  }

  return (
    <>
      <div className="mb-2">
        <div className="flex justify-between">
          {isOnSearchPage && (
            <>
              <div className="flex max-w-[75rem] mb-5">
                <TextDS2
                  agDesktop="Desktop/Caption/Medium"
                  agMobile="Desktop/Caption/Medium"
                  color="Primary/500"
                  className="min-w-max"
                >
                  {pagination.total_data} {t('common.dataResult')} |
                </TextDS2>
                <TextDS2
                  agDesktop="Desktop/Caption/Medium"
                  agMobile="Desktop/Caption/Medium"
                  color="Neutral/300"
                  className="ml-1"
                >
                  <span className="mr-1">Showing search result for</span>
                  <span className="font-bold">{`"${
                    searchKeyword || '-'
                  }"`}</span>
                </TextDS2>
              </div>

              <div className="flex justify-center mb-auto gap-3">
                {showToggle && (
                  <div>
                    <Toggle
                      label={toggleLabel}
                      onToggleChange={onClickToggle}
                    />
                  </div>
                )}
                {showSortButton && (
                  <SortDropdown
                    selectedSortType={selectedSortType!}
                    sortBtnItems={sortBtnItems!}
                  />
                )}
              </div>
            </>
          )}
        </div>

        <div className="flex justify-between">
          <div className="flex items-center gap-2">
            <Can permissions={['switch-obligation-status']}>
              <Checkbox
                checked={isAllItemsChecked || disabledAndChecked}
                indeterminate={isSomeItemsChecked}
                onChange={toggleSelectAll}
                isClickableLabel
                disabled={disabledAndChecked}
                data-testid={
                  checkboxSelectAllTestId || 'data-checklist-select-all'
                }
              >
                {isSimpleSelectAllMode && t('selectAll')}
              </Checkbox>
              {!isSimpleSelectAllMode && (
                <Dropdown
                  data-testid="selectAllBtn"
                  items={selectAllOptions}
                  trigger={
                    <div
                      className="flex gap-2 cursor-pointer"
                      data-testid="select-all-mode"
                    >
                      <TextDS2
                        agDesktop="Desktop/Body 3/Medium"
                        agMobile="Desktop/Body 3/Medium"
                        color="Neutral/300"
                      >
                        {selectedAllMode}
                      </TextDS2>
                      <CaretDown
                        size={15}
                        weight="fill"
                        className="text-neutral-500 my-auto"
                      />
                    </div>
                  }
                />
              )}
              <TextDS2
                agDesktop="Desktop/Body 3/Medium"
                agMobile="Desktop/Body 3/Medium"
                className="!leading-[22px] !tracking-[0.018px]"
                color="Neutral/200"
              >
                |
              </TextDS2>
            </Can>
            {titleRenderer ?? (
              <TextDS2
                agDesktop="Desktop/Body 3/Medium"
                agMobile="Desktop/Body 3/Medium"
                color="Neutral/300"
                className="!leading-[22px] !tracking-[0.018px]"
              >
                <span dangerouslySetInnerHTML={{ __html: String(title) }} />
              </TextDS2>
            )}
          </div>

          <div className="flex gap-4 mb-auto">
            {(!hideSelectedCount &&
              selectedCount?.({ selectedItems, items })) ?? (
              <TextDS2
                agDesktop="Desktop/Body 3/Semi Bold"
                agMobile="Desktop/Body 3/Semi Bold"
                color="Primary/400"
              >
                {selectedItems.length} {t('common.dataSelected')}
              </TextDS2>
            )}

            {!isOnSearchPage && (
              <>
                {showToggle && (
                  <div>
                    <Toggle
                      value={toggleValue}
                      label={toggleLabel}
                      onToggleChange={onClickToggle}
                    />
                  </div>
                )}

                {showSortButton && (
                  <SortDropdown
                    selectedSortType={selectedSortType!}
                    sortBtnItems={sortBtnItems!}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <div className="flex items-start gap-4">
        <div
          className={classNames(
            selectable && selectedItem ? 'flex-grow' : 'w-full'
          )}
        >
          <div className="space-y-1 -mx-1">
            <Divider />

            {items.length === 0 || (!searchKeyword && isOnSearchPage) ? (
              <EmptyState />
            ) : (
              items.map((item, index) => (
                <Fragment key={item.id}>
                  <DataChecklistItem
                    item={item}
                    searchQuery={searchQuery}
                    onCheckboxChange={(e) => handleCheckboxChange(e, item)}
                    onClick={() => handleItemClick(item, index)}
                    content={(contentProps) => itemRenderer(contentProps)}
                    hoverable={hoverable}
                    selected={selectable && selectedItem?.id === item.id}
                    disabledAndChecked={disabledAndChecked}
                    disabled={
                      (isSimpleSelectAllMode && item?.status === 'Inactive') ||
                      (selectedAllMode === 'Select all to confirm updates' &&
                        item?.confirmation_status !== 'need-confirmation') ||
                      (selectedAllMode === 'Select all to activate' &&
                        item?.is_activated) ||
                      (selectedAllMode === 'Select all to inactivate' &&
                        (!item?.is_activated ||
                          item?.confirmation_status === 'need-confirmation'))
                    }
                    tooltipText={
                      isSimpleSelectAllMode && item?.status === 'Inactive'
                        ? 'Can’t select this obligation because obligation is inactive.'
                        : (selectedAllMode ===
                              'Select all to confirm updates' &&
                              item?.confirmation_status !==
                                'need-confirmation') ||
                            (selectedAllMode === 'Select all to activate' &&
                              item?.is_activated) ||
                            (selectedAllMode === 'Select all to inactivate' &&
                              (!item?.is_activated ||
                                item?.confirmation_status ===
                                  'need-confirmation'))
                          ? 'Can’t select this obligation because different status with another selection'
                          : ''
                    }
                    isShowViewComplianceBtn={isShowViewComplianceBtn}
                    itemDataTestId={itemDataTestId}
                    itemCheckboxTestId={itemCheckboxTestId}
                  />
                  <Divider />
                </Fragment>
              ))
            )}
          </div>

          <TablePagination
            pagination={pagination}
            setPagination={setPagination}
          />
        </div>

        {selectable &&
          selectedItem &&
          selectedItemRenderer &&
          selectedItemRenderer({
            item: selectedItem,
            clear: () => setSelectedItem?.(undefined),
          })}
      </div>
    </>
  );
}

function SortDropdown({
  sortBtnItems,
  selectedSortType,
}: {
  sortBtnItems: IDropdownItemProps[];
  selectedSortType: string;
}) {
  return (
    <Dropdown
      data-testid="btnSort"
      items={sortBtnItems}
      trigger={
        <Button
          size="md"
          variant="text"
          tw="text-primary-main h-max flex px-0 my-auto"
        >
          <ArrowsDownUp size={18} />
          <span className="text-center ml-1">{`Sort${
            selectedSortType ? `: ${selectedSortType}` : ''
          }`}</span>
        </Button>
      }
    />
  );
}
