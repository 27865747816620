import {
  ChangeEvent,
  ForwardedRef,
  Fragment,
  forwardRef,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { MagnifyingGlass, CaretDown, CaretRight } from 'phosphor-react';
import { Checkbox, TextDS2, TextInput } from '@hol-jsp/dashboard-dsl';
import {
  autoUpdate,
  useFloating,
  useClick,
  useDismiss,
  offset,
  size,
  useInteractions,
  FloatingPortal,
  autoPlacement,
  flip,
} from '@floating-ui/react';
import { DataItem } from 'types/general';
import { Plus, Minus } from 'phosphor-react';
import classnames from 'classnames';

export interface CustomRef<T extends HTMLElement = HTMLDivElement> {
  clearFilter: () => void;
}

interface FilterProps<T extends DataItem<T>> {
  data: T[];
  onChange: (checked: T[]) => void;
  reset: boolean;
  flip?: boolean;
  autoPlacement?: boolean;
  updateData: boolean;
  disabled?: boolean;
  placeholder?: string;
  onSearch?: (value: string) => void;
}

type CheckType = 'plus' | 'minus';

interface CheckboxFilterProps<T extends DataItem<T>> {
  item: T;
  selected: T[];
  onChange: (data: T, withChildren: boolean, type?: CheckType) => void;
  changeStateCollapse: (item: T) => void;
  search?: string;
  showCheckOrUncheckAllChildren?: boolean;
  idx?: number;
}

const OFFSET = 10;

const calculateIfChildrenAllChecked = <T extends DataItem<T>>(
  children: T[] | undefined
): boolean => {
  if (!children || children.length === 0) {
    return false;
  }

  let hasUnchecked = false;

  for (const child of children) {
    if (!child.checked) {
      hasUnchecked = true;
    }

    if (child.children && child.children.length > 0) {
      const childAllChecked = calculateIfChildrenAllChecked(child.children);

      if (!childAllChecked) {
        return false;
      }
    }
  }

  return !hasUnchecked;
};

const CheckboxFilter = <T extends DataItem<T>>({
  item,
  onChange,
  selected,
  changeStateCollapse,
  search,
  showCheckOrUncheckAllChildren,
  idx,
}: CheckboxFilterProps<T>) => {
  const isAllChildrenChecked = useMemo(() => {
    return calculateIfChildrenAllChecked(item.children);
  }, [item.children]);

  return (
    <>
      <div
        className="py-1 flex group"
        data-testid={idx != null ? `filter-search-item-${idx}` : undefined}
      >
        <div
          className={classnames('flex space-x-2')}
          style={{
            paddingLeft: item.level ? `${(item.level - 1) * 12}px` : '0px',
          }}
        >
          {showCheckOrUncheckAllChildren &&
            (!item.children ||
              (item.children && item.children.length === 0)) && (
              <CaretRight size={16} className="invisible" />
            )}
          {showCheckOrUncheckAllChildren &&
            item.children &&
            item.children.length > 0 &&
            (!isAllChildrenChecked || !item.checked ? (
              <Plus
                size={16}
                color="black"
                className="cursor-pointer opacity-0 group-hover:opacity-100 shrink-0"
                onClick={() => {
                  onChange(item, true, 'plus');
                }}
                height={20}
              />
            ) : (
              <Minus
                size={16}
                color="black"
                className="cursor-pointer opacity-0 group-hover:opacity-100 shrink-0"
                onClick={() => {
                  onChange(item, true, 'minus');
                }}
                height={20}
              />
            ))}
          <Checkbox
            value={item.id}
            checked={selected.some(
              (selectedItem) => selectedItem.id === item.id
            )}
            onChange={() => {
              onChange(item, false);
            }}
          />
          {(!item.children || (item.children && item.children.length === 0)) &&
            item.level &&
            item.level > 1 && <CaretRight size={16} className="invisible" />}
          {item.children && item.children.length > 0 && item.collapsed && (
            <CaretRight
              size={16}
              height={20}
              color="black"
              className="!cursor-pointer"
              onClick={() => {
                changeStateCollapse(item);
              }}
            />
          )}
          {item.children &&
            item.children.length > 0 &&
            item.collapsed === false && (
              <CaretDown
                size={16}
                color="black"
                className="!cursor-pointer"
                onClick={() => {
                  changeStateCollapse(item);
                }}
                height={20}
              />
            )}
          <TextDS2
            agDesktop="Desktop/Caption/Medium"
            agMobile="Desktop/Caption/Medium"
            color="Neutral/400"
          >
            <span
              dangerouslySetInnerHTML={{
                __html: search
                  ? item.label.replace(
                      new RegExp(search, 'gi'),
                      (match) => `<span class="bg-[#F8CA0F]">${match}</span>`
                    )
                  : item.label,
              }}
            />
          </TextDS2>
        </div>
      </div>
      {!item.collapsed &&
        item.children &&
        item.children.map((child: T) => (
          <CheckboxFilter
            key={child.id}
            item={child}
            onChange={onChange}
            selected={selected}
            changeStateCollapse={(childItem) => {
              changeStateCollapse(childItem);
            }}
            search={search}
            showCheckOrUncheckAllChildren={showCheckOrUncheckAllChildren}
          />
        ))}
    </>
  );
};

const SearchFilter = forwardRef(function <T extends DataItem<T>>(
  props: FilterProps<T>,
  ref: ForwardedRef<CustomRef<HTMLDivElement>>
) {
  const {
    data,
    onChange,
    reset,
    flip: isFlip = true,
    autoPlacement: isAutoPlacement = false,
    updateData,
    disabled = false,
    placeholder,
    onSearch,
  } = props;
  const [dataItems, setDataItems] = useState<T[]>(
    JSON.parse(JSON.stringify(data) || '[]') ?? []
  );
  const [search, setSearch] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [filteredData, setFilteredData] = useState<T[]>(
    JSON.parse(JSON.stringify(data) || '[]') ?? []
  );

  // update dataItems when data changed
  useEffect(() => {
    if (updateData) setDataItems(data);
  }, [updateData, data]);

  useEffect(() => {
    const getFilteredData = (items: T[], search: string): T[] => {
      const filterItem = (
        item: T,
        isParentCollapsed: boolean
      ): T | undefined => {
        const labelMatches = item.label
          .toLowerCase()
          .replace(/\s+/g, '')
          .includes(search.toLowerCase().replace(/\s+/g, ''));
        const newItem: T = { ...item };

        if (newItem.children && newItem.children.length > 0) {
          newItem.children = newItem.children
            .map((child) =>
              filterItem(child, newItem.collapsed || isParentCollapsed)
            )
            .filter((child) => !!child) as T[];

          newItem.collapsed = false;
        }

        return labelMatches || (newItem.children && newItem.children.length > 0)
          ? newItem
          : undefined;
      };

      if (search === '') {
        return items;
      }

      return items
        .map((item) => filterItem(item, false))
        .filter((item) => !!item) as T[];
    };

    setFilteredData(getFilteredData(dataItems, search));
  }, [search, dataItems]);

  const { refs, floatingStyles, context, strategy } = useFloating({
    strategy: 'fixed',
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      offset(OFFSET),
      size({
        apply: ({ availableHeight, elements, rects }) => {
          Object.assign(elements.floating.style, {
            maxHeight: `${Math.max(50, availableHeight - OFFSET)}px`,
            width: `${rects.reference.width + 104}px`,
          });
        },
      }),
      isFlip && flip(),
      isAutoPlacement && autoPlacement(),
    ],
    whileElementsMounted: autoUpdate,
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
  ]);

  const updateChildrenCollapsedStatus = (items: T[], newStatus: boolean) => {
    return items.map((dataItem) => {
      if (dataItem.children && dataItem.children.length > 0) {
        dataItem.children = updateChildrenCollapsedStatus(
          dataItem.children,
          newStatus
        );
      }
      dataItem.collapsed = newStatus;
      return dataItem;
    });
  };

  const toggleCollapsedStatus = (item: T) => {
    const newStatus = !item.collapsed;
    const toggleItem = (items: T[]) => {
      return items.map((dataItem) => {
        if (dataItem.id === item.id) {
          dataItem.collapsed = newStatus;
          if (
            newStatus === true &&
            dataItem.children &&
            dataItem.children.length > 0
          ) {
            dataItem.children = updateChildrenCollapsedStatus(
              dataItem.children,
              newStatus
            );
          }
        } else if (dataItem.children && dataItem.children.length > 0) {
          dataItem.children = toggleItem(dataItem.children);
        }
        return dataItem;
      });
    };

    setFilteredData(toggleItem(filteredData));
  };

  function updateDataItemStatus(
    dataItems: T[],
    dataItem: T,
    checked: boolean,
    withChildren: boolean
  ) {
    const findAndUpdateItem = (items: T[]): T[] => {
      return items.map((item) => {
        if (item.id === dataItem.id) {
          item.checked = checked;
          if (withChildren && item.children && item.children.length > 0) {
            item.children = updateChildrenStatus(item.children, checked);
          }
        } else if (item.children && item.children.length > 0) {
          item.children = findAndUpdateItem(item.children);
          // if (withChildren)
          //   item.checked = calculateParentCheckedStatus(item.children);
        }
        return item;
      });
    };

    const updatedDataItems = findAndUpdateItem(dataItems);

    onChange(getCheckedItem(updatedDataItems));
    setDataItems(updatedDataItems);
  }

  function updateChildrenStatus<T extends DataItem<T>>(
    children: T[],
    checked: boolean
  ): T[] {
    return children.map((child) => {
      const updatedChild = { ...child };
      updatedChild.checked = checked;
      if (updatedChild.children && updatedChild.children.length > 0) {
        updatedChild.children = updateChildrenStatus(
          updatedChild.children,
          checked
        );
      }
      return updatedChild;
    });
  }

  function calculateParentCheckedStatus<T extends DataItem<T>>(
    children: T[] | undefined
  ): boolean {
    return !!children && children.every((child) => child.checked);
  }

  function strip(html: string) {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
  }

  const getJoinedLabels = (dataItems: T[]): string => {
    const traverseItems = (items: T[]): string[] => {
      const labels: string[] = [];

      for (const item of items) {
        if (item.checked) {
          labels.push(strip(item.label));
        } else if (item.children && item.children.length > 0) {
          labels.push(...traverseItems(item.children));
        }
      }

      return labels;
    };

    return traverseItems(dataItems).join(', ');
  };

  const getCheckedItem = (items: T[]) => {
    const checkedItem: T[] = [];
    const recursiveCheck = (item: T, res: T[]) => {
      if (item.checked) {
        checkedItem.push(item);
      }
      if (item.children && item.children.length > 0) {
        item.children.forEach((child) => {
          recursiveCheck(child, res);
        });
      }
    };
    items.forEach((item) => {
      recursiveCheck(item, checkedItem);
    });
    return checkedItem;
  };

  useEffect(() => {
    if (reset) {
      setSearch('');
      setDataItems(data);
      setFilteredData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  // use memo to check whether at least 1 item have children
  const showCheckOrUncheckAllChildren = useMemo(() => {
    return dataItems.some((item) => item.children && item.children.length > 0);
  }, [dataItems]);

  return (
    <div onClick={() => (!disabled ? setIsOpen(true) : null)}>
      <TextInput
        className="text-neutral-400 text-medium text-xs flex-grow focus:outline-none h-12"
        icon={<MagnifyingGlass size={20} />}
        placeholder={placeholder}
        rightIcon={<CaretDown size={20} />}
        value={isOpen ? search : getJoinedLabels(dataItems)}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          onSearch?.(e.target.value);
          setSearch(e.target.value);
        }}
        {...getReferenceProps({ ref: refs.setReference })}
        disabled={disabled}
      />
      {isOpen && (
        <FloatingPortal>
          <div
            className="bg-white focus:outline-none z-50 shadow-lg rounded-md overflow-auto px-4 py-2 border-[1px] border-neutral-100 !max-h-[136px] overflow-scroll"
            {...getFloatingProps({
              ref: refs.setFloating,
              style: { ...floatingStyles, position: strategy },
            })}
          >
            {filteredData.map((item, idx) => (
              <Fragment key={item.id}>
                <CheckboxFilter
                  item={item}
                  onChange={(
                    data: T,
                    withChildren: boolean,
                    type?: CheckType
                  ) => {
                    const checked =
                      type === 'plus'
                        ? true
                        : type === 'minus'
                          ? false
                          : !data.checked;
                    updateDataItemStatus(
                      dataItems,
                      data,
                      checked,
                      withChildren
                    );
                  }}
                  selected={getCheckedItem(dataItems)}
                  changeStateCollapse={(item) => {
                    toggleCollapsedStatus(item);
                  }}
                  search={search}
                  showCheckOrUncheckAllChildren={showCheckOrUncheckAllChildren}
                  idx={idx}
                />
              </Fragment>
            ))}
            {filteredData.length === 0 && (
              <div className="text-center text-neutral-400 text-medium text-xs font-inter font-medium">
                No data found
              </div>
            )}
          </div>
        </FloatingPortal>
      )}
    </div>
  );
});

SearchFilter.displayName = 'SearchFilter';
export default SearchFilter;
