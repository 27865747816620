import { AxiosError, AxiosResponse } from 'axios';
import { useQuery, useMutation } from 'react-query';
import {
  getUserList,
  getDomainList,
  getCompanyRoleList,
  postInviteUser,
  getFilterOptions,
  putUpdateUser,
  updateUserStatus,
  GetUserListParams,
  getRenewPasswordEligibility,
} from './service';
import { RespDomainList, User } from 'types/user';
import { IPagination } from 'types/general';
import { PrivilegeResponse } from 'types/privilege';

export interface UserList {
  data: User[];
  page: IPagination;
}

export const useInviteUser = () =>
  useMutation({
    mutationFn: postInviteUser,
  });

export const useUpdateUser = () =>
  useMutation({
    mutationFn: putUpdateUser,
  });

export const useGetDomainList = (companyId: string) =>
  useQuery<AxiosResponse<RespDomainList>, AxiosError<{ error: string }>>({
    queryKey: ['domain-list', companyId],
    queryFn: () => getDomainList(companyId),
    keepPreviousData: true,
    staleTime: 0,
    retry: false,
  });

export const useGetCompanyRoleList = (companyId: string) =>
  useQuery<AxiosResponse<PrivilegeResponse>, AxiosError<{ error: string }>>({
    queryKey: ['role-list', companyId],
    queryFn: () => getCompanyRoleList(companyId),
    keepPreviousData: true,
    staleTime: 0,
    retry: false,
  });

export const useGetUserList = (params: GetUserListParams) =>
  useQuery({
    queryKey: ['user-list', params],
    queryFn: () => getUserList(params),
    keepPreviousData: true,
  });

export const useGetFilterOptions = () =>
  useQuery({
    queryKey: 'filter-options',
    queryFn: () => getFilterOptions(),
  });

export const useUpdateUserStatus = () =>
  useMutation({
    mutationFn: updateUserStatus,
  });

export const useGetRenewPasswordEligibility = (isStandardLoginType: boolean) =>
  useQuery({
    queryKey: 'renew-password-eligibility',
    queryFn: () => getRenewPasswordEligibility(),
    staleTime: 0,
    enabled: !!isStandardLoginType,
  });
