import { useMutation } from 'react-query';
import {
  login,
  logout,
  validateResetPasswordToken,
  resetPassword,
  postForgotPassword,
  postCheckToken,
  postCheckUsername,
  postAcceptInvitation,
  loginAzure,
  renewPassword,
  verifyOtp,
  resendOtp,
} from './service';

export const useLogin = () =>
  useMutation({
    mutationFn: login,
  });

export const useVerifyOtp = () =>
  useMutation({
    mutationFn: verifyOtp,
  });

export const useResendOtp = () =>
  useMutation({
    mutationFn: resendOtp,
  });

export const useLoginAzure = () =>
  useMutation({
    mutationFn: loginAzure,
  });

export const useLogout = (params: Record<string, any>) =>
  useMutation({
    mutationFn: logout,
    ...params,
  });

export const useValidateResetPasswordToken = () =>
  useMutation({
    mutationFn: validateResetPasswordToken,
    mutationKey: ['validateResetPasswordToken'],
  });

export const useResetPassword = () =>
  useMutation({
    mutationFn: resetPassword,
    mutationKey: ['resetPassword'],
  });

export const useForgotPassword = () =>
  useMutation({
    mutationFn: postForgotPassword,
    mutationKey: ['forgotPassword'],
  });

export const useCheckToken = () =>
  useMutation({
    mutationFn: postCheckToken,
  });

export const useCheckUsername = () =>
  useMutation({
    mutationFn: postCheckUsername,
  });

export const useAcceptInvitation = () =>
  useMutation({
    mutationFn: postAcceptInvitation,
  });

export const useRenewPassword = () =>
  useMutation({
    mutationFn: renewPassword,
  });
