/* eslint-disable import/prefer-default-export */
import { getCompanyId } from 'helper';
import axios from 'modules/axios';
import {
  inviteUserReqBody,
  RespDomainList,
  RespFilterOptions,
  UserListResponse,
} from 'types/user';
import { PrivilegeResponse } from 'types/privilege';
import { RootResponse } from '@/types/general';

export interface PaginatedParams {
  page?: number;
  limit?: number;
}

export interface GetUserListParams extends PaginatedParams {
  /**
   * @deprecated Deprecated in new API. Contact BE for more information.
   */
  filter?: string;
  search?: string;
  company_id?: string;
  role?: string;
  status?: string;
  group?: string;
  show_all?: number | boolean;
  not_in_group?: string;
}

export async function getUserList(params: GetUserListParams) {
  const companyId = getCompanyId();

  return axios.get<UserListResponse>(`/v1/users`, {
    params: {
      ...params,
      company_id: companyId,
    },
  });
}

export function postInviteUser(data: inviteUserReqBody) {
  const {
    domain,
    email,
    fullName,
    privilege,
    companyId,
    whatsappNumber,
    receiveWhatsappNotif,
  } = data;
  const payload = {
    email: `${email}${domain}`,
    name: fullName,
    role_id: privilege,
    whatsapp_number: whatsappNumber,
    receive_whatsapp_notif: receiveWhatsappNotif,
  };

  return axios
    .post(`/v1/users/invite?company_id=` + companyId, payload)
    .then((res) => res.data);
}

export function putUpdateUser(payload: {
  userId: string;
  roleId: string;
  whatsappNumber?: string;
  receiveWhatsappNotif?: boolean;
}) {
  const { userId, roleId, whatsappNumber, receiveWhatsappNotif } = payload;
  const companyId = getCompanyId();
  return axios
    .put(
      `/v1/users/${userId}`,
      {
        role_id: roleId,
        whatsapp_number: whatsappNumber,
        receive_whatsapp_notif: receiveWhatsappNotif,
      },
      {
        params: {
          company_id: companyId,
        },
      }
    )
    .then((res) => res.data);
}

export async function getDomainList(companyId: string) {
  return axios.get<RespDomainList>(`/v1/email-domains`, {
    params: {
      company_id: companyId,
    },
  });
}

export async function getCompanyRoleList(companyId: string) {
  return axios.get<PrivilegeResponse>(`/v1/roles`, {
    params: {
      company_id: companyId,
      show_all: true,
      role_type: 'SYSTEM',
    },
  });
}

export async function getFilterOptions() {
  const companyId = getCompanyId();
  return axios.get<RespFilterOptions>(`v1/users/options`, {
    params: {
      company_id: companyId,
    },
  });
}

export async function updateUserStatus({
  userId,
  status,
}: {
  userId: string;
  status: string;
}) {
  const companyId = getCompanyId();

  if (status === 'deleted') {
    return axios
      .delete(`/v1/users/${userId}`, {
        params: {
          company_id: companyId,
        },
      })
      .then((res) => res.data);
  }

  return axios
    .put(
      `/v1/users/${userId}/status`,
      { status },
      {
        params: {
          company_id: companyId,
        },
      }
    )
    .then((res) => res.data);
}

// v1/users/renew-password-eligibility
export const getRenewPasswordEligibility = () => {
  const companyId = getCompanyId();
  return axios.get<
    RootResponse<{ is_admin: boolean; is_renew_password: boolean }>
  >(`v1/users/renew-password-eligibility`, {
    params: {
      company_id: companyId,
    },
  });
};
